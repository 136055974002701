.OrganizationDetailDialog .Checkbox:has(.disabled-check) {
  margin-top: 1rem;
}

.OrganizationDetailDialog .breadcrumb-label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.OrganizationDetailDialog .parents-breadcrumb {
  margin-bottom: 1rem;
  font-size: 0;
}
.OrganizationDetailDialog .parents-breadcrumb > span {
  display: inline-flex;
  align-items: center;
}
.OrganizationDetailDialog .parents-breadcrumb > span {
  font-size: 0.9rem;
  margin-bottom: 0.25rem;
}
.OrganizationDetailDialog .parents-breadcrumb > span::after {
  content: '>';
  padding: 0 0.25rem;
  font-weight: bold;
  font-size: 0.7rem;
  color: var(--deletebutton-bg-color);
}
