.BlueprintBlueprintSearchResult {
}

.BlueprintSearchResultHeader {
  padding: 0.5rem 0 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.BlueprintSearchResultHeader .back-page {
  position: absolute;
  left: -2rem;
  top: 0.5rem;
}
.BlueprintSearchResultHeader > .condition {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  align-items: flex-start;
  position: relative;
}
.BlueprintSearchResultHeader .condition-detail {
  width: calc(100% - 14rem); /* ざっくり ボタンサイズ + 余白 分を除く */
  background-color: var(--input-bg-color-disabled);
  border-radius: 0.3rem;
  padding: 0.5rem;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
}
.BlueprintSearchResultHeader .condition-detail > .conditions {
  width: calc(100% - 2rem);
  line-height: 1.4rem;
}
.BlueprintSearchResultHeader .condition-detail > .conditions.close {
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.BlueprintSearchResultHeader .condition-detail > .conditions.open {
  white-space: pre-line;
}
.BlueprintSearchResultHeader .adjustment-order-categories {
  display: flex;
  gap: 2rem;
  margin: 0.5rem 0;
}
.BlueprintSearchResultHeader .adjustment-order-categories > a {
  cursor: pointer;
  text-decoration: underline;
}
.BlueprintSearchResultHeader .search-keyword {
  display: flex;
  align-items: center;
}
.BlueprintSearchResultHeader .search-keyword .IconButton {
  margin-top: 0.8rem;
}
.BlueprintSearchResultHeader .search-keyword .ErrorMessage {
  display: none;
}
.BlueprintSearchResultHeader .result {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
  padding-right: 1rem;
}
.BlueprintSearchResultHeader .grid-size > :first-child {
  border: 1px solid var(--dialog-frame-color);
  border-radius: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding: 0.5rem;
  width: auto;
}
.BlueprintSearchResultHeader .grid-size > :last-child {
  border: 1px solid var(--dialog-frame-color);
  border-left: 0;
  border-radius: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 0.5rem;
  width: auto;
}
.BlueprintSearchResultHeader .bold {
  font-weight: bold;
}

.BlueprintSearchResultList {
}
.BlueprintSearchResultList > .list-body {
  overflow-y: auto;
}
.BlueprintSearchResultList > .list-body > .list-contents {
  padding: 1rem;
}
.BlueprintSearchResultList > .list-body > .list-contents > .items {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1rem;
  row-gap: 2rem;
  margin-bottom: 2rem;
}

.BlueprintSearchResultItem > .header {
  display: flex;
  align-items: center;
}
.BlueprintSearchResultItem > .detail {
  display: flex;
  gap: 0.5rem;
  align-items: flex-start;
}
.BlueprintSearchResultItem > .detail > .thumbnail {
  border: 1px solid var(--input-frame-color);
  display: flex;
}
.BlueprintSearchResultItem > .detail > .thumbnail > img:not(.loaded),
.BlueprintSearchResultItem > .detail > .thumbnail > img.error {
  min-height: 7rem;
}
.BlueprintSearchResultItem > .detail > .thumbnail:has(img.error),
.BlueprintSearchResultItem > .detail > .thumbnail:has(img:not([src])) {
  position: relative;
  color: var(--input-font-color-disabled);
  background-color: var(--input-bg-color-disabled);
  align-items: center;
  justify-content: center;
}
.BlueprintSearchResultItem > .detail > .thumbnail:has(img.error)::after,
.BlueprintSearchResultItem > .detail > .thumbnail:has(img:not([src]))::after {
  content: 'No Image';
  position: absolute;
}
.BlueprintSearchResultItem > .detail > .match-result {
  list-style: none;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
  font-size: 0.9rem;
}

/* 結果一覧要素(大) */
.BlueprintSearchResultItem {
  /* 一般的なディスプレイサイズの範囲では 2 列とする */
  width: calc(50% - calc(1rem / 2));
}
@media screen and (min-width: 1920px) {
  /* 特大のディスプレイでは3列とする */
  .BlueprintSearchResultItem {
    width: calc(33% - calc(2rem / 2));
  }
}

/* 小さいディスプレイではテキスト欄の幅を優先して確保する */
.BlueprintSearchResultItem > .detail > .thumbnail {
  width: calc(100% - 10rem);
  min-width: 10rem;
}
.BlueprintSearchResultItem > .detail > .match-result {
  width: 10rem;
}
@media screen and (min-width: 1024px) {
  /* 中サイズのディスプレイでは 10rem + 10% がテキスト欄の幅とし、緩やかに広がるようにする */
  .BlueprintSearchResultItem > .detail > .thumbnail {
    width: calc(90% - 10rem);
    min-width: 10rem;
  }
  .BlueprintSearchResultItem > .detail > .match-result {
    width: 10rem;
  }
}
@media screen and (min-width: 1440px) {
  /* 大きなディスプレイでは完全に固定比率とする */
  .BlueprintSearchResultItem > .detail > .thumbnail {
    width: 60%;
  }
  .BlueprintSearchResultItem > .detail > .match-result {
    width: 40%;
  }
}

/* 結果一覧要素(小) */
.BlueprintSearchResultItem.small {
  width: 21rem;
}
.BlueprintSearchResultItem.small > .detail > .thumbnail {
  width: calc(100% - 11rem);
}
.BlueprintSearchResultItem.small > .detail > .match-result {
  width: 11rem;
}

.BlueprintSearchResultItem > .detail > .thumbnail > img {
  width: 100%;
}
