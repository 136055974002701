.DialogSample .categories {
  margin-bottom: 3em;
}
.DialogSample h2 {
  border-bottom: 1px solid var(--basic-line-color);
}

.DialogSample h3:not(.input-blocktitle) {
  border-left: 0.5em solid var(--basic-line-color);
  padding-left: 0.5em;
  box-sizing: border-box;
}

.DialogSample h4 {
  margin-bottom: 0.5em;
}

.DialogSample .output {
  color: var(--basic-font-color);
  background-color: var(--input-bg-color-disabled);
  padding: 0.7em 0.5em;
  margin: 0 0 2em;
  border-radius: 4px;
  position: relative;
  min-height: calc(var(--input-box-height, 2.5rem) * 2);
  box-sizing: border-box;
  overflow-x: auto;
}
.DialogSample :has(> .output) {
  position: relative;
}
.DialogSample :has(> .output)::before {
  content: '入力結果';
  position: absolute;
  margin-top: -0.3rem;
  font-size: 0.5rem;
  z-index: 1;
}

.DialogSample .tabPanel {
  font-size: 0.8rem;
  font-weight: bold;
}

.DialogSample .space-between-buttons {
  margin-top: 2rem;
}

.DialogSample .description {
  background-color: var(--input-bg-color-disabled);
  padding: 0.3rem;
  font-weight: 600;
}
