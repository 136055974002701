.ForumLatestComment {
  background-color: var(--record2-bg-color);
  padding: 0.5rem;
}

.ForumLatestComment.our {
  background-color: var(--record1-bg-color);
  border-radius: 0.75rem;
  border-bottom-right-radius: 0;
}
.ForumLatestComment.their {
  background-color: var(--record2-bg-color);
  border-radius: 0.75rem;
  border-top-left-radius: 0;
}

.ForumLatestComment .reply-detail {
  font-size: var(--basic-small-font-size);
  margin-left: 0.25rem;
  border-left: 0.25rem solid var(--button-bg-color);
  padding-left: 0.5rem;
  margin-bottom: 0.25rem;
}

.ForumLatestComment .comment-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.ForumLatestComment .metadata {
  margin: 0 0 0.4rem;
  font-size: 0.7rem;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  align-items: flex-end;
  flex-wrap: wrap;
}
.ForumLatestComment .metadata :nth-child(2) {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 15em;
}

.ForumLatestComment p {
  font-size: 0.9rem;
  white-space: pre-wrap;
  word-break: break-all;
  margin: 0;
}
