/* 検索結果欄（リスト部分）の開閉判断関係CSS */
.Filterbox .click-area {
  /* 検索結果欄が表示されたら画面全体を覆う */
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw !important;
  height: 100vh;
}

/* 選択済みアイテム及び検索欄 */
.Filterbox .main-contents {
  position: relative;
}

/* ここから下はデザイン */

/* 検索キーワード入力エリア */
.Filterbox .input-area {
  display: flex;
  grid-column: 1 / 6;
  background-color: var(--pulldown-searcharea-bg-color);
}

/* 選択されたラベル表示エリア */
.Filterbox .selected-item-area {
  background-color: var(--pulldown-bg-color);
  min-height: var(--input-box-height, 2.5rem);
  display: grid;
  align-items: center;
  grid-template-columns: 9fr 1fr;
}

.Filterbox .filter-opener {
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  outline: none;
}

.Filterbox .selected-item-area:has(.filter-opener:focus-visible) {
  outline: var(--basic-outline-style);
}

.Filterbox .disabled {
  background-color: var(--input-bg-color-disabled);
}

.Filterbox .show {
  box-shadow: 0.2rem 0.2rem 0.5rem;
}

/* 検索キーワード入力と検索ボタン表示エリア */
.Filterbox .search-area {
  background-color: var(--pulldown-searcharea-bg-color);
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  justify-self: center;
  align-self: center;
  font-size: var(--basic-font-size);
  padding: 0.5em;
  width: 100%;
  box-sizing: border-box;
}

.Filterbox input::placeholder {
  color: var(--pulldown-font-color);
  opacity: 0.5;
}

/* 検索結果表示エリア */
.Filterbox .search-result-area {
  background-color: var(--pulldown-bg-color);
}

/* 検索結果のページ送り・戻し 表示エリア */
.Filterbox .search-page-area {
  background-color: var(--pulldown-bg-color);
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-template-rows: 2rem;
}

/* gridの最終列に配置 */
.Filterbox .end-column {
  grid-column: -2;
}

/* gridの要素を中央に配置 */
.Filterbox .center {
  justify-self: center;
  align-self: center;
}

/* 検索キーワード入力欄 */
.Filterbox .keyword-input {
  width: 90%;
  background-color: var(--pulldown-searcharea-bg-color);
  border: none;
  color: var(--pulldown-font-color);
  outline: none;
  font-size: var(--basic-font-size);
}

/* ホバー判定用クラス */
.Filterbox .enable-hover-color {
}

/* 選択済みアイテムの文字部分 */
.Filterbox .selected-label {
  vertical-align: middle;
}

/* 選択済みアイテム */
.Filterbox .selected-item {
  display: inline-block;
  margin: 0 0.5em;
  word-break: break-all;
  max-width: 100%;
}

/* svgは垂直方向で真ん中に配置 */
.Filterbox svg {
  vertical-align: middle;
}

.Filterbox .disabled-svg-icon {
  opacity: 0.3;
}

/* 検索結果アイテムホバー時の処理 */
.Filterbox .enable-hover-color:hover,
.Filterbox .enable-hover-color.focus {
  background-color: var(--pulldown-resultarea-bg-color-hover);
}

.Filterbox .main-contents svg {
  fill: var(--input-icon-color);
  height: var(--input-icon-height);
  width: var(--input-icon-width);
}

.Filterbox .search-box {
  width: 20em;
}

.Filterbox .search-box.hidden {
  height: 0px;
}

.Filterbox .search-item {
}

.Filterbox .search-item.hidden {
  padding: 0;
  height: 0px;
}

.Filterbox .search-item.selected {
  background-color: var(--input-bg-color-disabled);
}
.Filterbox .search-item.selected:hover,
.Filterbox .search-item.selected.focus {
  background-color: var(--input-bg-color-disabled-hover);
}

.Filterbox .search-item:hover .search-item-description {
  visibility: visible;
  transition-duration: 0;
  transition-delay: 500ms;
}

.Filterbox .search-item-description {
  visibility: hidden;
  position: absolute;
  z-index: 2;
  left: 0px;
  padding: 10px;
  font-size: var(--basic-small-font-size);
  line-height: 1.6rem;
  color: var(--input-font-color);
  border-radius: 0.3em;
  background: var(--input-frame-color);
}

.Filterbox .viewItemText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0.5rem;
  height: 1rem;
}

.Filterbox .topping-label-with-help {
  display: flex;
  align-items: center;
}

.Filterbox .HelpIcon {
  height: auto;
  width: 1rem;
  margin-left: 0.2rem;
}

.Filterbox .HelpIcon svg {
  height: auto;
  width: 1rem;
}

.Filterbox .search-focus-area:focus {
  outline: #f00;
}
