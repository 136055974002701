.FilterSave {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  height: 2.3rem;
}

.FilterSave .user-setting-selector {
  margin-top: -0.8rem;
}

.FilterSave .user-setting-selector .ErrorMessage {
  display: none;
}
