.container {
  width: fit-content;
  min-width: 100%;
  height: 100%;
}

.container .content {
  min-height: calc(100vh - var(--header-height, 2.6rem));
  display: flex;
  justify-content: space-between;
  padding: var(--header-height, 2.6rem) var(--toolbar-width, 5vw) 0
    var(--toolbar-width, 5vw);
}

.container .main {
  width: calc(100vw - var(--toolbar-width, 5vw) * 2);
  padding: var(--container-main-contents-padding, 0.5rem);
  box-sizing: border-box;
}

.container .click-area {
  /* サイドメニューが表示されたら画面全体を覆う */
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
