.CompanyConfirmation {
  /* 全体的にコンパクトにとのことでフォントを若干小さめに指定 */
  font-size: 0.9rem;
}

.CompanyConfirmation .tabPanelContents .tabPanel {
  width: auto;
  min-width: 6rem;
}

.CompanyConfirmation .header .pageNavigation {
  display: inline-flex;
  width: auto;
  margin-right: 2rem;
}

.CompanyConfirmation .header .contact {
  display: inline-block;
}

.CompanyConfirmation .company-name {
  margin: 1rem 0;
  font-size: 1.2rem;
  font-weight: bold;
}

.CompanyConfirmation .certificates {
  margin-top: 2rem;
}

.CompanyConfirmation .certificates > span {
  border-left: 0.5em solid var(--basic-line-color);
  padding-left: 0.5em;
  box-sizing: border-box;
  margin-bottom: 0.5rem;
  display: block;
}

.CompanyConfirmation .certificates .SimpleListView .list-header {
  min-height: 2.5rem;
}

.CompanyConfirmation .certificates .SimpleListView li {
  min-height: auto;
}

/* 基本情報 */
.CompanyConfirmation .basic-info-table {
  border-collapse: collapse;
  width: 100%;
}

.CompanyConfirmation .basic-info-table .root-header {
  background-color: var(--listheader-bg-color);
  color: var(--listheader-font-color);
  width: 8rem;
  min-width: 8rem;
}

.CompanyConfirmation .basic-info-table th {
  font-weight: normal;
  text-align: left;
  vertical-align: top;
  width: 8rem;
  min-width: 8rem;
  background-color: var(--listsum-bg-color);
  padding: 0.5rem;
  border-bottom: 1px solid var(--input-frame-color);
}

.CompanyConfirmation .basic-info-table td {
  vertical-align: top;
  background-color: var(--record2-bg-color);
  padding: 0.5rem;
  border-bottom: 1px solid var(--input-frame-color);
  white-space: pre-wrap;
  width: 50%;
  min-width: 8rem;
}

/* 拠点/設備 (拠点) */
.CompanyConfirmation .branch-list {
  list-style: none;
  margin: 0;
  padding: 0;
  min-height: 2rem;
}

.CompanyConfirmation .branch-list > li {
  display: grid;
  grid-template-areas: 'name region count overview';
  grid-template-columns: 10rem 7rem 9rem 1fr;
}

.CompanyConfirmation .branch-list > li > div {
  white-space: pre-wrap;
}

.CompanyConfirmation .branch-list > li > div:nth-child(1) {
  grid-area: name;
}

.CompanyConfirmation .branch-list > li > div:nth-child(2) {
  grid-area: region;
}

.CompanyConfirmation .branch-list > li > div:nth-child(3) {
  grid-area: count;
}

.CompanyConfirmation .branch-list > li > div:nth-child(4) {
  grid-area: overview;
}

.CompanyConfirmation .branch-list > li > div:nth-child(5) {
  grid-area: facilities;
  padding: 0 0.5rem 1.5rem;
}

.CompanyConfirmation .branch-list .branch-list-header {
  background-color: var(--listheader-bg-color);
  color: var(--listheader-font-color);
  min-height: 2.5rem;
  align-items: center;
}

.CompanyConfirmation .branch-list .branch-list-header > div {
  margin: 0 0.5em;
}

.CompanyConfirmation .branch-list .branch-list-row {
  background-color: var(--record2-bg-color);
}

.CompanyConfirmation .branch-list .branch-list-row > div {
  padding: 0.5rem;
}

.CompanyConfirmation .branch-list .show-facility {
  grid-template-areas:
    'name region count overview'
    'facilities facilities facilities facilities';
}

.CompanyConfirmation .branch-list .branch-list-row:not(:last-child) {
  border-bottom: 1px solid var(--input-frame-color);
}

.CompanyConfirmation .show-label {
  margin-left: 1rem;
  font-size: var(--basic-small-font-size);
  text-decoration: underline;
  cursor: pointer;
}

/* 拠点/設備 (設備) */
.CompanyConfirmation .facility-list {
  list-style: none;
  margin: 0 0 0 6rem;
  padding: 0;
  border-left: 1px solid var(--input-frame-color);
  border-right: 1px solid var(--input-frame-color);
}

.CompanyConfirmation .facility-list > li {
  display: grid;
  grid-template-columns: 8rem 8rem 8rem 4rem 1fr;
}

.CompanyConfirmation .facility-list > li > div {
  padding: 0.5rem;
}

.CompanyConfirmation .facility-list .facility-list-header {
  background-color: var(--listsum-bg-color);
}

.CompanyConfirmation .facility-list .facility-list-row {
  border-bottom: 1px solid var(--input-frame-color);
}

.CompanyConfirmation .strength-list .SimpleListView .list-header {
  min-height: 2.5rem;
}

.CompanyConfirmation .strength-list .SimpleListView li {
  min-height: auto;
}

.CompanyConfirmation .strength-list .SimpleListView li > div > span {
  white-space: pre-wrap;
}

/* 技術情報 */
.CompanyConfirmation .skill-list {
  list-style: none;
  margin: 0;
  padding: 0;
  min-height: 2rem;
}

.CompanyConfirmation .skill-list li {
  display: grid;
  grid-template-columns: 10rem 1fr 2fr;
}

.CompanyConfirmation .skill-list li > div {
  padding: 0.5rem;
}

.CompanyConfirmation .root-category {
  background-color: var(--listheader-bg-color);
  color: var(--listheader-font-color);
}

.CompanyConfirmation .child-category {
  background-color: var(--listsum-bg-color);
}

.CompanyConfirmation li:not(:last-child) .child-category {
  border-bottom: 1px solid var(--input-frame-color);
}

.CompanyConfirmation .skills {
  background-color: var(--record2-bg-color);
}

.CompanyConfirmation li:not(:last-child) .skills {
  border-bottom: 1px solid var(--input-frame-color);
}

/* 発注分析・受注分析 */
.CompanyConfirmation .chart-area {
  margin: 1rem;
}

.CompanyConfirmation .mixed-input-line [class*='w-']:has(.CaptionButton) {
  margin-bottom: calc(1.5rem - 0.8rem);
  align-self: center;
}
.CompanyConfirmation .mixed-input-line [class*='w-']:has(.Radio) {
  margin-top: 0.8rem;
  align-self: center;
}

.CompanyConfirmation .input-with-icon {
  display: flex;
  align-items: center;
}
.CompanyConfirmation .input-with-icon > .Filterbox {
  width: calc(100% - 2rem);
}
.CompanyConfirmation .input-with-icon > .IconButton {
  margin-left: 0.25rem;
  margin-bottom: 0.8rem;
}

.CompanyConfirmation .analysis-form-area {
  margin-bottom: 1rem;
}
.CompanyConfirmation .date-range {
  display: flex;
  align-items: center;
}
.CompanyConfirmation .date-range > div:has(.DateSuggest) {
  width: 100%;
}
.CompanyConfirmation .date-range .aid {
  margin: 0 0.6rem 0.5rem;
}
