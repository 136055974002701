.FileViewer {
  display: grid;
  grid-template-columns: 15rem 1fr;
}

.FileViewer .margin {
  margin-left: 1.5rem;
}

.FileViewer .right-align {
  margin: 0 1rem 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.FileViewer .file-header {
  display: flex;
  line-height: 2.89rem;
  letter-spacing: 0.2em;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: var(--fileviewer-list-header-bg-color);
  color: var(--fileviewer-list-font-color);
}

.FileViewer .file-menu svg {
  fill: var(--fileviewer-list-font-color);
  margin: 0 0.3rem;
}

.FileViewer .file-menu ul > li > a {
  display: block;
  width: 100%;
  line-height: 2.89rem;
  letter-spacing: 0.2em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* user agentを打ち消し */
.FileViewer .menu-list {
  margin: 0;
  padding: 0;
}

.FileViewer .file-menu .menu-list .contents {
  display: block;
  border-bottom: 0.1rem solid;
  background-color: var(--fileviewer-list-contents-bg-color);
  color: var(--fileviewer-list-font-color);
  padding-left: 1.5rem;
}

.FileViewer .file-menu {
  margin-top: 1.6rem;
}

.FileViewer a {
  color: var(--fileviewer-list-font-color);
}

.FileViewer .file-menu .menu-list li:hover {
  background-color: var(--fileviewer-list-contents-bg-color-hover);
}

.FileViewer .selected {
  background-color: var(
    --fileviewer-list-contents-bg-color-selected
  ) !important;
}
