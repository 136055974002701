.FormItemDialog .form-item-id {
  display: flex;
  font-size: 0.8rem;
  justify-content: flex-end;
}

.FormItemDialog .Checkbox {
  margin-top: 1.4em;
}

.FormItemDialog .select-item-container {
  margin: 1rem 0;
}

.FormItemDialog .link-button {
  overflow-wrap: anywhere;
  text-decoration: underline;
  color: var(--link-font-color);
  background-color: transparent;
  cursor: pointer;
}

.FormItemDialog .button-padding {
  padding-top: 0.8rem;
}
