.blueprint-confirmation-header-information {
  margin: 1.5rem 0;
  display: flex;
}

.blueprint-confirmation-header-information .edit-icon {
  margin-top: 2.5rem;
}

.blueprint-confirmation-header-information .information {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}

.blueprint-confirmation-header-information .displayNameLang {
  font-weight: 700;
}

.blueprint-confirmation-header-information .usable {
  align-content: center;
}
