.BlueprintBlueprintInput .usable {
  line-height: 4rem;
}

.BlueprintBlueprintInput .upload-file-area {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.BlueprintBlueprintInput .upload-file-area .filename {
  align-content: center;
}

.BlueprintBlueprintInput .upload-file-area a {
  text-decoration: underline;
  cursor: pointer;
}

.BlueprintBlueprintInput .side-search-icon {
  margin-top: 1.5rem;
}

.BlueprintBlueprintInput .input-line .item-group-100.no-space {
  gap: 0 0;
}

.BlueprintBlueprintInput .input-line .item-group-100 .item-group-100.no-space {
  width: inherit;
}
