.PartialLoading {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  overflow: hidden;
  z-index: 10;
}
div:has(> .PartialLoading) {
  position: relative;
}
