:root {
  --basic-font-size: 1rem;
  --basic-big-font-size: 1.2rem;
  --basic-small-font-size: 0.7rem;
  --input-error-font-size: 0.7rem;
  --input-box-height: 2.5rem;
  --input-icon-height: 1.5rem;
  --input-icon-width: 1.5rem;
  --header-height: 2.6rem;
  --footer-max-height: 10rem;
  --container-main-contents-padding: 0.5rem;
  --icon-row-padding: 0.2rem;
  --toolbar-width: 5vw;
  --listheader-height: 3.8rem;
  --listheader-icon-height: 1.4rem;
  --listheader-icon-width: 1.4rem;
  --listitem-height: 2.8rem;
  --listitem-icon-height: 1.7rem;
  --listitem-icon-width: 1.7rem;
  --listitem-gap: 2rem;
  --menu-list-max-height: 200px;
  --menu-list-width: 500px;
  --button-line-gap: 2rem;
  --max-z-index: 9999;
  --overlay-z-index: 2000;
  --basic-outline-style: var(--outline-color) auto 1px;
}

:focus-visible {
  /* フォーカスが当たった時の基本スタイル */
  outline: var(--basic-outline-style);
}

/* ダイアログなどの背景 */
::backdrop {
  background-color: var(--basic-bg-color-disabled);
}

html,
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  width: 100%;
  height: 100%;
  font-size: var(--basic-font-size);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  width: 100vw;
  height: 100vh;
}

/* スクロール時に表示部分のみしかbackground-colorが適用されない問題に対処 */
#back-ground-color-theme {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--basic-bg-color);
  z-index: -1;
}

/* ローディング表示 */
#loading-icon-back-layer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--basic-bg-color-disabled);
  z-index: var(--overlay-z-index, 2000);
}

#theme {
  color: var(--basic-font-color);
}

a {
  color: var(--link-font-color);
}

/*
　　セレクトボックスの作り直し後に削除
*/
/* ここから */
select {
  position: relative;
  width: 100%;
  appearance: none;
  font-size: var(--basic-font-size);
  border: 0.09rem solid var(--pulldown-frame-color);
  background-color: var(--pulldown-bg-color);
  color: var(--pulldown-font-color);
  border-radius: 0.2rem;
  padding: 0.5rem 1.2rem 0.5rem 0.5rem;
}

select:disabled {
  border: 0.1rem solid var(--input-frame-color-disabled);
  background-color: var(--input-bg-color-disabled);
  color: var(--input-font-color-disabled);
}
/* ここまで */

/* 入力項目の基本的なボーダー */
.basic-input-border {
  border: 0.09rem solid var(--input-frame-color);
  border-radius: 0.2rem;
}

/* 入力項目のエラー時のボーダー */
.basic-error-input-border {
  border: 0.09rem solid var(--input-frame-color-error);
  border-radius: 0.2rem;
}

/* 入力項目の無効時のボーダー */
.basic-disabled-input-border {
  border: 0.09rem solid var(--input-frame-color-disabled);
  border-radius: 0.2rem;
}

/* 入力欄の左肩に表示されるラベル */
.topping-label {
  position: relative;
  top: 0.4rem;
  left: 0.3rem;
  background: linear-gradient(
    180deg,
    var(--basic-bg-color) 0%,
    var(--basic-bg-color) 55%,
    var(--input-bg-color) 55%,
    var(--input-bg-color) 100%
  );
  width: fit-content;
  font-size: var(--basic-small-font-size);
  color: var(--titlelabel-font-color);
  z-index: 1;
}

/* 入力欄の左肩に表示されるラベル 入力欄無効時 */
.disabled-topping-label {
  background: linear-gradient(
    180deg,
    var(--basic-bg-color) 0%,
    var(--basic-bg-color) 55%,
    var(--input-bg-color-disabled) 55%,
    var(--input-bg-color-disabled) 100%
  );
}

.topping-label-checkbox {
  position: relative;
  top: 0.4rem;
  padding-bottom: 0.2rem;
  width: fit-content;
  font-size: var(--basic-small-font-size);
  color: var(--titlelabel-font-color);
  z-index: 1;
}

/* 必須項目の場合、*を付与 */
.required .topping-label::after,
.required .topping-label-checkbox::after {
  content: '*';
  display: inline-block;
  color: var(--input-font-color-error);
}

.btn-nomal {
  border: none;
  background-color: transparent;
}

.btn-basic {
  border: none;
  background-color: var(--button-color);
}

.btn-basic svg {
  fill: #fff;
}

/* チェックボックスとラジオボタンで左肩に表示されるラベル */
.grouping-label {
  position: relative;
  top: 0.4rem;
  left: 0.3rem;
  background-color: var(--basic-bg-color);
  width: fit-content;
  font-size: var(--basic-small-font-size);
  color: var(--titlelabel-font-color);
  z-index: 1;
}

/*
  react-datepickerの日付選択ダイアログ
  XXX: 日付選択ダイアログがz-index指定されている他要素と重なってしまうため追加
*/
.react-datepicker-popper {
  z-index: 10 !important;
}

.input-areatitle {
  font-size: var(--basic-font-size);
  color: var(--header-font-color);
  font-weight: bold;
  margin: 0 0 2rem;
  padding: 2rem 0 0.4rem 0;
  border-bottom: 1px solid var(--basic-line-color);
}

.input-blocktitle {
  position: relative;
  max-width: 14.89rem;
  width: 100%;
  padding: 0.85rem 0.85rem 0.85rem 4rem;
  color: var(--header-font-color);
  line-height: 1;
  border: 0.07rem solid var(--titlelabel-font-color);
  border-radius: 2.6rem;
}

.input-blocktitle::before {
  content: '';
  position: absolute;
  top: -0.1rem;
  left: 1.2rem;
  bottom: 0;
  margin: auto;
  height: 0.6rem;
  width: 0.6rem;
  border-left: 2px solid var(--titlelabel-font-color);
  border-bottom: 2px solid var(--titlelabel-font-color);
  transform: rotate(-45deg);
}

.close .input-blocktitle::before {
  top: 0.1rem;
  transform: rotate(135deg);
}

.input-blocktitle-outer {
  position: relative;
}

.input-blocktitle-outer::after {
  content: '';
  position: absolute;
  top: 0;
  left: 19.79rem;
  bottom: 0;
  height: 0.045rem;
  width: calc(100% - 19.79rem);
  margin: auto;
  background-color: var(--titlelabel-font-color);
}

.input-blockbody.close {
  display: none;
}

.input-blockbody.margin-bottom {
  margin-bottom: 1rem;
}

.input-blockbody.indent {
  margin-left: 1.5rem;
}

/* 常に最下部に固定される操作エリア */
.footer {
  min-height: 6rem;
  max-height: var(--footer-max-height, 10rem);
  position: fixed;
  z-index: 10;
  left: 0;
  bottom: 0;
  width: calc(100vw - var(--toolbar-width));
  margin: auto;
  background-color: var(--footer-bg-color);
  box-shadow: 0 -0.1rem var(--footer-border-color);
  /*
  横幅でボタン溢れる場合はスクロール
  display:flexにする場合は以下を削除
  */
  overflow-x: auto;
}

.footer > .footer-contents {
  width: 85vw;
  margin: 0.8rem auto;
}

/* svgアイコンの無効時表現 */
.disabled-svg-icon {
  opacity: 0.3;
}

/* 汎用コメントダイアログのテキストエリア */
.Dialog .modal-content .comment-confirm-daialog .Textarea textarea {
  min-height: 4.8rem;
  max-width: 32.75rem;
  width: 100%;
}

/* 汎用コメントダイアログのフッター */
.Dialog .modal-content .comment-confirm-footer {
  padding: 1em 0 0;
  justify-content: center;
  display: flex;
}

/* 汎用コメントダイアログのボタン */
.Dialog .modal-content .comment-confirm-daialog .btn {
  width: 12em;
  margin: 0 0.4rem;
}

/* 汎用コメントダイアログの幅 */
.Dialog .modal-content:has(.comment-confirm-daialog) {
  max-width: 45rem;
}

/* 汎用ダイアログのテキストエリア */
.Dialog .modal-content .confirm-daialog .Textarea textarea {
  min-height: 4rem;
  max-width: 32rem;
  width: 100%;
  line-height: 3rem;
}

/* 汎用ダイアログのフッター */
.Dialog .modal-content .confirm-footer {
  padding: 1em 0 0;
  justify-content: center;
  display: flex;
}

/* 汎用ダイアログのボタン */
.Dialog .modal-content .confirm-daialog .btn {
  width: 12em;
  margin: 0 0.4rem;
}

/* 汎用ダイアログの幅 */
.Dialog .modal-content:has(.confirm-daialog) {
  max-width: 45rem;
}

/* レイアウト統一用のデザイン */

/* 入力行 */
.input-line {
  display: flex;
  gap: 0 var(--listitem-gap, 2rem);
}

.input-line > div:not(.item-group),
.input-line > div:not(.item-group) > div {
  width: 20rem;
}

/*
  入力グループ
    - 要素幅を`%`にて指定するためのグループ
    - class名は`item-group-<number>`(<number>はグループ要素幅（%）を指定)
*/
.input-line > div[class^='item-group-'],
.input-line > div[class*=' item-group-'] {
  display: flex;
  gap: inherit;
  flex-shrink: 0;
}

.item-group-100 {
  flex-basis: 100%;
}

/* 要素幅(`%`指定） */
.input-line > div[class^='item-group-'] > div.w-100,
.input-line > div[class*=' item-group-'] > div.w-100 {
  flex-basis: calc(100% - var(--listitem-gap, 2rem));
  flex-shrink: 0;
}

.input-line > div[class^='item-group-'] > div.w-80,
.input-line > div[class*=' item-group-'] > div.w-80 {
  flex-basis: calc(80% - var(--listitem-gap, 2rem));
  flex-shrink: 0;
}

.input-line > div[class^='item-group-'] > div.w-77,
.input-line > div[class*=' item-group-'] > div.w-77 {
  flex-basis: calc(77% - var(--listitem-gap, 2rem));
  flex-shrink: 0;
}

.input-line > div[class^='item-group-'] > div.w-75,
.input-line > div[class*=' item-group-'] > div.w-75 {
  flex-basis: calc(75% - var(--listitem-gap, 2rem));
  flex-shrink: 0;
}

.input-line > div[class^='item-group-'] > div.w-66,
.input-line > div[class*=' item-group-'] > div.w-66 {
  flex-basis: calc(66.666% - var(--listitem-gap, 2rem));
  flex-shrink: 0;
}

.input-line > div[class^='item-group-'] > div.w-60,
.input-line > div[class*=' item-group-'] > div.w-60 {
  flex-basis: calc(60% - var(--listitem-gap, 2rem));
  flex-shrink: 0;
}

.input-line > div[class^='item-group-'] > div.w-50-nogap,
.input-line > div[class*=' item-group-'] > div.w-50-nogap {
  flex-basis: calc(50%);
  flex-shrink: 0;
  width: 0rem;
}

.input-line > div[class^='item-group-'] > div.w-50,
.input-line > div[class*=' item-group-'] > div.w-50 {
  flex-basis: calc(50% - var(--listitem-gap, 2rem));
  flex-shrink: 0;
}

.input-line > div[class^='item-group-'] > div.w-40,
.input-line > div[class*=' item-group-'] > div.w-40 {
  width: calc(40% - var(--listitem-gap, 2rem));
  flex-shrink: 0;
}

.input-line > div[class^='item-group-'] > div.w-33,
.input-line > div[class*=' item-group-'] > div.w-33 {
  width: calc(33.333% - var(--listitem-gap, 2rem));
  flex-shrink: 0;
}

.input-line > div[class^='item-group-'] > div.w-25,
.input-line > div[class*=' item-group-'] > div.w-25 {
  width: calc(25% - var(--listitem-gap, 2rem));
  flex-shrink: 0;
}

.input-line > div[class^='item-group-'] > div.w-23,
.input-line > div[class*=' item-group-'] > div.w-23 {
  width: calc(23% - var(--listitem-gap, 2rem));
  flex-shrink: 0;
}

.input-line > div[class^='item-group-'] > div.w-20,
.input-line > div[class*=' item-group-'] > div.w-20 {
  width: calc(20% - var(--listitem-gap, 2rem));
  flex-shrink: 0;
}

.input-line > div[class^='item-group-'] > div.w-16,
.input-line > div[class*=' item-group-'] > div.w-16 {
  width: calc(100% / 6 - var(--listitem-gap, 2rem));
  flex-shrink: 0;
}

.input-line > div[class^='item-group-'] > div.w-12,
.input-line > div[class*=' item-group-'] > div.w-12 {
  width: calc(12% - var(--listitem-gap, 2rem));
  flex-shrink: 0;
}

.input-line > div[class^='item-group-'] > div.w-25-icon,
.input-line > div[class*=' item-group-'] > div.w-25-icon {
  width: calc(25% + 30px - var(--listitem-gap, 2rem));
  flex-shrink: 0;
}

/* テキストエリア幅(`%`指定） */
.input-line > div[class^='item-group-'] textarea.w-100,
.input-line > div[class*=' item-group-'] textarea.w-100 {
  width: calc(100% - calc(var(--listitem-gap, 2rem) / 2));
}

.input-line > div[class^='item-group-'] textarea.w-80,
.input-line > div[class*=' item-group-'] textarea.w-80 {
  width: calc(80% - var(--listitem-gap, 2rem) / 2);
}

.input-line > div[class^='item-group-'] textarea.w-75,
.input-line > div[class*=' item-group-'] textarea.w-75 {
  width: calc(75% - var(--listitem-gap, 2rem) / 2);
}

.input-line > div[class^='item-group-'] textarea.w-50,
.input-line > div[class*=' item-group-'] textarea.w-50 {
  width: calc(50% - var(--listitem-gap, 2rem) / 2);
}

div[class^='item-group-'] > div,
div[class*=' item-group-'] > div,
div[class^='item-group-'] > div[class^='w-'] > div,
div[class*=' item-group-'] > div[class^='w-'] > div,
div[class^='item-group-'] > div[class*=' w-'] > div,
div[class*=' item-group-'] > div[class*=' w-'] > div,
div[class^='item-group-'] .TextBox input,
div[class*=' item-group-'] .TextBox input,
div[class^='item-group-'] > div[class^='w-'] .TextBox input,
div[class*=' item-group-'] > div[class^='w-'] .TextBox input,
div[class^='item-group-'] > div[class*=' w-'] .TextBox input,
div[class*=' item-group-'] > div[class*=' w-'] .TextBox input {
  /*
    XXX: 入力コンポーネントの`div`及び`input`要素に width: 100%; を指定していないとサイズが変更されない。
    各入力コンポーネントがレスポンシブに対応できるようになった場合は以下の`width: 100%;`の指定は不要になる想定
  */
  width: 100%;
}

/* テキストエリア（高さ） */
div[class^='item-group-'] textarea.mh-short,
div[class*=' item-group-'] textarea.mh-short,
div.item-group textarea.mh-short {
  min-height: 2rem;
}

div[class^='item-group-'] textarea.mh-normal,
div[class*=' item-group-'] textarea.mh-normal,
div.item-group textarea.mh-normal {
  min-height: 4rem;
}

div[class^='item-group-'] textarea.mh-middle,
div[class*=' item-group-'] textarea.mh-middle,
div.item-group textarea.mh-middle {
  min-height: 6rem;
}

div[class^='item-group-'] textarea.mh-high,
div[class*=' item-group-'] textarea.mh-high,
div.item-group textarea.mh-high {
  min-height: 8rem;
}

/* ボタン行 */
.button-line {
  display: flex;
  gap: 0 var(--button-line-gap, 2rem);
}

/* input系のエラー表示判定用ダミークラス */
.visible-error-message {
}

/* 通知エリア */
.notification-area.close {
  display: none;
}

.notification-display-area {
  background-color: var(--input-bg-color);
  padding: 1rem;
  min-height: 3rem;
  margin: 1rem 0 1rem 1.5rem;
  color: var(--input-font-color-error);
  white-space: pre-wrap;
}

/* タブ制御 */
.tabPanelContents {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 0.1em solid var(--titlelabel-font-color);
  padding-left: 1.5rem;
}

.tabPanelContents .tabPanel {
  width: 10rem;
  padding: 0.5rem 0.3rem;
  text-align: center;
  border: 0.1em solid var(--titlelabel-font-color);
  border-bottom: 0rem;
}

.tabPanelContents .tabPanel.current {
  color: var(--button-font-color);
  background-color: var(--button-bg-color);
}

.scroll-main-contents-area {
  padding: 1rem 0rem 0rem 0rem;
}

/* インポートダイアログ */
.inputfields {
  display: flex;
}

.inputfields > div {
  margin: 0 2rem 0 0;
  width: 12rem;
}
