/*
　tenant独自のcss定義
　admin,tenant共有のcssはshared/css/index.cssに定義
 */

/* TODO: 削除予定 ⬇️ここから⬇️ */
.inputfields {
  display: flex;
}

.inputfields .long {
  width: calc(100% - 37.83%);
  margin: 0 4.5% 0 0;
}

.inputfields .small {
  margin: 0 4.5% 0 0;
  width: 12rem;
}

.inputfields .middle {
  width: 28.83%;
  margin: 0 4.5% 0 0;
}

.inputfields .minor {
  width: 18%;
  padding: 0 2.5% 0 0;
}

.inputfields .minor:last-child {
  padding: 0;
}

.inputfields .minor input {
  width: 14.38%;
}

.inputfields .minor [disabled] {
  line-height: 1.4rem;
}

.inputfields .minor-cover {
  width: calc(100% - 23.33%);
}

.inputfields.long > div {
  width: 16rem;
}

.inputfields > div {
  margin: 0 2rem 0 0;
  width: 12rem;
}

.inputfields .no-spece {
  padding: 0;
}

.inputfields.textarea > div {
  width: 100%;
  flex: 1;
}

.inputfields textarea {
  min-height: 7.04rem;
  min-width: 100%;
  line-height: 1.7;
}

.inputfields .required .topping-label::after {
  content: '*';
  display: inline-block;
  color: var(--input-font-color-error);
}
/* TODO: 削除予定 ⬆️ここまで⬆️ */
