.BlueprintBlueprintConfirmationBlueprint .fix-baseline {
  display: flex;
  align-items: center;
}

.BlueprintBlueprintConfirmationBlueprint .BlueprintFileReplaceNote {
  margin: 2rem 0;
  font-size: 0.9rem;
}

.BlueprintBlueprintConfirmationBlueprint .sticky-area {
  position: sticky;
  top: calc(var(--header-height) + 1rem); /* ヘッダ+余白分開けて追従する */
}
