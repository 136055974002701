.QAitem .contents {
  display: flex;
  padding-bottom: 1rem;
}

.QAitem .contents .icon-area svg {
  margin-top: 0.7rem;
  width: 2rem;
  height: 2rem;
  fill: var(--button-bg-color);
  color: var(--button-bg-color);
}

.QAitem .contents .qa-area {
  padding-left: 0.3rem;
}

.QAitem .contents .qa-area .title {
  font-weight: 700;
}

.QAitem .contents .qa-area .answer {
  margin-bottom: 0.5rem;
  white-space: pre-wrap;
}
