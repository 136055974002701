.Dialog dialog[open] {
  padding: 0;
  border: 0;
  border-radius: 10px;
  display: grid;
  grid-template-rows: 3.5rem 1fr;
}

.Dialog .modal-header {
  position: relative;
  height: 1.5rem;
  background-color: var(--dialogheader-bg-color);
  color: var(--dialogheader-font-color);
  border-radius: 10px 10px 0 0;
  padding: 1rem;
}

.Dialog .modal-header .closeBtn {
  background: #0000;
  border: none;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.Dialog .modal-header .closeBtn svg {
  width: 1.5rem;
  height: 1.5rem;
  fill: white;
}

.Dialog .modal-title {
  color: var(--titlelabel-font-color);
}

.Dialog .modal-content {
  width: 80%;
  max-height: 85%;
  background-color: var(--dialog-bg-color);
}

.Dialog .modal-body {
  padding: 0.5rem 1rem 1rem 1rem;
  color: var(--dialog-font-color);
  overflow: auto;
}
.Dialog .modal-body:focus,
.Dialog .modal-body:focus-visible {
  outline: none;
}

.Dialog .btn-area {
  display: flex;
  justify-content: center;
  padding: 1em 0 0;
}

.Dialog .btn-area .button {
  width: 12em;
  margin: 0 0.4em;
}

.Dialog .btn-area svg {
  height: 0.6em;
  width: 0.6em;
  margin-right: 0.4em;
  border: 1px solid var(--dialog-bg-color);
}

.Dialog .modal-footer {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 1.5rem;
  min-height: 4rem;
  background-color: var(--footer-bg-color);
  border-top: 1px solid var(--footer-border-color);
  border-radius: 0 0 10px 10px;

  position: sticky;
  bottom: 0;
}
