.NoticeCard {
  height: calc(100vh - var(--header-height));
  margin: 0;
  position: fixed;
  top: var(--header-height);
  right: 0;
  display: flex;
  background-color: var(--menu-bg-color);
  z-index: 20;
  padding-top: 2rem;
}

.NoticeCard .Tabs .tabPanelContents {
  padding-right: 10rem;
}

.ListTab {
  height: calc(100vh - 12rem);
  overflow: auto;
  margin-bottom: 1rem;
  padding-right: 2rem;
}

.ListTab ul {
  padding-left: 0px;
}
