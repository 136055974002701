.AdminAccountInput .side-search-icon {
  margin-top: 1.5rem;
}

.AdminAccountInput .input-line .item-group-100.no-space {
  gap: 0 0;
}

.AdminAccountInput .input-line .item-group-100 .item-group-100.no-space {
  width: inherit;
}
