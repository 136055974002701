.Login {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Login p {
  margin: 0;
}

.Login .error {
  color: var(--input-font-color-error);
}

.login-sction {
  display: flex;
  max-width: 54em;
  width: 100%;
  box-shadow: 0 4px 6px 0 rgb(0 0 0 / 16%);
}
.Login .visual-area,
.Login .input-area {
  flex: 0 0 50%;
  max-width: 50%;
  width: 100%;
}

.Login .visual-area {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--accent-bg-color);
  text-align: center;
  color: var(--accent-font-color);
}

.Login .visual-area .logo img {
  max-width: 2em;
  width: 6em;
  fill: var(--accent-icon-color);
}

.Login .visual-area .font {
  display: inline-block;
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 1;
}

.Login .input-area {
  padding: 3em;
  background-color: var(--card-bg-color);
  box-sizing: border-box;
}

.Login .input-area .title {
  margin: 0;
  padding: 0 0 1em;
  text-align: center;
  font-size: 1em;
}

.Login .input-field {
  padding: 0 0 1em;
}

.Login .input-field a {
  color: var(--link-font-color);
}

.Login .input-field a {
  cursor: pointer;
}
.Login .input-field:last-child {
  padding: 0;
}

.Login .password label {
  position: relative;
  display: block;
}

.Login .password img {
  position: absolute;
  right: 0.6em;
  bottom: 0.6em;
  max-width: 1.4em;
}

.Login .password .center svg:last-child {
  fill: var(--input-icon-color);
}

.Login .submit {
  text-align: center;
}

.Login .btn {
  width: 100%;
  font-weight: bold;
}

.Login .sso-submit {
  margin-top: 0.5rem;
  text-align: center;
}

.Login .btn.limit {
  max-width: 24em;
}

.Login .submit p:empty {
  display: none;
}

.Login .forget {
  text-align: center;
}

.Login .google button {
  padding: 0;
  cursor: pointer;
  background-color: var(--ssobutton-bg-color);
  color: var(--ssobutton-font-color);
}

.Login .submit .basic {
  padding: 1em;
}
.Login .google .btn-normal {
  font-weight: 400;
  border: 1px solid var(--ssobutton-frame-color);
}

.Login .google .btn-normal img {
  top: 0.8em;
  left: 0.8em;
  width: 1.4em;
}

.Login .policy-area {
  font-size: var(--basic-small-font-size, 0.7rem);
  padding-bottom: 2em;
}

.Login .admin-login {
  margin-top: 1.5rem;
}

.Login .input-field.sso {
  padding: 0 0 0.3rem;
}

.Login .input-field.sso.fix-space {
  padding: 4rem 0 2.5rem 0;
}

.Login .red-note {
  font-size: var(--basic-small-font-size, 0.7rem);
  color: var(--input-font-color-error);
}
