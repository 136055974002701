.FormPageBuilder .form-area {
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  align-items: center;
  padding-left: 2rem;
  margin-bottom: 2rem;
  border-top: 1px solid var(--titlelabel-font-color);
}

.FormPageBuilder .form-item {
  min-height: 4rem;
  border: 1px dashed var(--input-font-color);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 0.5rem;
  margin: 0.4rem;
}

.FormPageBuilder .form-item-size-1 {
  width: calc(25% - 0.5rem * 2);
}

.FormPageBuilder .form-item-size-2 {
  width: calc(50% - 0.5rem * 2);
}

.FormPageBuilder .form-item-size-3 {
  width: calc(75% - 0.5rem * 2);
}

.FormPageBuilder .form-item-size-4 {
  width: calc(100% - 0.5rem * 2);
}

.FormPageBuilder .form-item-size-textarea {
  width: calc(75% - 0.5rem * 2);
  margin-right: calc(25% - 0.5rem * 2);
}

.FormPageBuilder .form-item-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.FormPageBuilder .form-id {
  font-size: 0.5rem;
  overflow-wrap: anywhere;
}

.FormPageBuilder .form-item-control {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-inline-start: 0;
  align-items: center;
  margin: 0;
  height: 2rem;
}

.FormPageBuilder .form-item-control .size-select {
  min-width: 3rem;
  margin-bottom: -1rem;
}

.FormPageBuilder .form-item-add {
  width: calc(25% - 1rem * 2);
  border: 1px dashed var(--input-font-color);
  text-align: left;
  margin: 1rem;
  padding: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.FormPageBuilder .form-item-add:hover {
  opacity: 0.85;
  transition-duration: 150ms;
}
