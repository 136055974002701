.BlueprintBlueprintSearch {
  /* 全体的にコンパクトにとのことでフォントを若干小さめに指定 */
  font-size: 0.9rem;
}

.BlueprintBlueprintSearch .section-head {
  font-size: 0.9rem;
  border-left: 0.5rem solid var(--basic-line-color);
  padding-left: 0.5rem;
  box-sizing: border-box;
}

.BlueprintBlueprintSearch .condition-section {
  padding: 0 1rem 1rem;
}

.BlueprintBlueprintSearch .size .TextBox .main-area {
  width: 9rem;
}

.BlueprintBlueprintSearch .TextBox .main-area {
  height: 2rem;
}

.BlueprintBlueprintSearch .TextBox input {
  font-size: 0.8rem;
}

.BlueprintBlueprintSearch .Checkbox {
  margin-top: 0.8rem;
}

.indent {
  padding-left: 2rem;
  padding-right: 2rem;
}

/* 詳細条件 共通 */
.BlueprintBlueprintSearch .detail-condition {
  display: flex;
  flex-wrap: wrap;
  width: 70%;
}
.BlueprintBlueprintSearch .detail-condition dt {
  width: 9rem;
  padding: 0.5rem;
  box-sizing: border-box;
  background-color: var(--group-bg-color);
  border-bottom: 1px solid var(--input-frame-color);
}
.BlueprintBlueprintSearch .detail-condition dd {
  width: calc(100% - 10em);
  margin: 0;
  padding: 0.5rem 0.5rem 0 0.5rem;
  box-sizing: border-box;
  background-color: var(--input-bg-color);
  border-bottom: 1px solid var(--input-frame-color);
}
.BlueprintBlueprintSearch .condition-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.BlueprintBlueprintSearch .condition-list-item {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid var(--input-frame-color);
}
.BlueprintBlueprintSearch .condition-list-item-actions > *:not(:last-child) {
  margin-right: 0.5rem;
}
.BlueprintBlueprintSearch .condition-list-item-actions {
  margin: 0.5rem 0;
}
.BlueprintBlueprintSearch .condition-list-item-actions > a {
  text-decoration: underline;
  cursor: pointer;
}
.BlueprintBlueprintSearch .condition-list-item-actions > a:hover {
  opacity: 0.7;
}

.BlueprintBlueprintSearch .condition-list-item-detail {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.BlueprintBlueprintSearch .condition-list-item-detail .ConditionCheckbox {
  padding: 0.5rem;
  width: 10rem;
}
.BlueprintBlueprintSearch .TextBox:has(.keyword) .ErrorMessage {
  display: none;
}

/* 画像検索　形状 */
.BlueprintBlueprintSearch .image .Checkbox .ErrorMessage {
  display: none;
}

.BlueprintBlueprintSearch .label-with-input {
  display: flex;
  align-items: center;
}
.BlueprintBlueprintSearch .label-with-input > span {
  text-wrap: nowrap;
  margin-left: 0.5rem;
  margin-bottom: 1.5rem;
}

.BlueprintBlueprintSearch .segment-button {
  text-align: center;
}

/* 詳細条件　詳細 */
.BlueprintBlueprintSearch .Radio {
  line-height: 2.5rem;
}

.BlueprintBlueprintSearch .searchOption {
  margin-right: 0.2rem;
}
.BlueprintBlueprintSearch .Radio .unit {
  margin-right: 0.2rem;
}
.BlueprintBlueprintSearch .Radio label {
  margin-right: 1rem;
}
.BlueprintBlueprintSearch .Radio label:last-child {
  margin-right: 0;
}
.BlueprintBlueprintSearch .Radio .ErrorMessage {
  display: none;
}

.BlueprintBlueprintSearch .TextBox:has(.keywords) .ErrorMessage {
  display: none;
}

.BlueprintBlueprintSearch .search-direction {
  margin: 0 0 0.2rem 0.2rem;
}

.BlueprintBlueprintSearch .label-with-input-with-space {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
}

.BlueprintBlueprintSearch .label-with-input-with-space p {
  min-width: fit-content;
  margin: 0.6rem 0.5rem 0 0.5rem;
}
.BlueprintBlueprintSearch .label-with-input-with-space p.label {
  width: 7rem;
  min-width: 4rem;
  margin: 0.7rem 0 0 0;
}
.BlueprintBlueprintSearch .label-with-input-with-space p.prefix {
  margin: 0.6rem 0 0 0;
}

/* アクション */
.BlueprintBlueprintSearch .footer {
  display: flex;
  align-items: center;
}
.BlueprintBlueprintSearch .footer-contents > * {
  margin-right: 1rem;
}
.BlueprintBlueprintSearch .fetch-reset-button {
  color: var(--link-font-color);
  text-decoration: underline;
}
.BlueprintBlueprintSearch .fetch-reset-button:hover {
  opacity: 0.7;
}

.BlueprintBlueprintSearch .detail-condition.image .label-with-input .label {
  min-width: fit-content;
  margin-right: 0.3rem;
}
