.AttributeInputDialog .Dialog .modal-content {
  max-height: 80%;
  max-width: 80%;
}

.AttributeInputDialog .AttributeHeader {
  padding: 0.6rem 0 1rem 1.5rem;
}

.AttributeInputDialog .AttributeHeader .Checkbox {
  margin-top: 1.5rem;
}
