.GroupTreeEditor {
}

.GroupSearch {
  display: flex;
  align-items: center;
}
.GroupSearch > div:first-child {
  flex-basis: calc(50% - var(--listitem-gap, 2rem));
  flex-shrink: 0;
}
.GroupSearch .search-button {
  margin-left: 0.3rem;
  margin-bottom: 0.6rem;
}

.GroupTreeControls {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 1rem;
}
.GroupTreeControls .Checkbox .ErrorMessage {
  display: none;
}

.GroupTreeControls .link-button {
  text-decoration: underline;
  color: var(--link-font-color);
  background-color: transparent;
  cursor: pointer;
}

.GroupTreeItemRoot {
  padding: 0.5rem;
}
.GroupTreeItemRoot.selected {
  outline: var(--basic-outline-style);
}
.GroupTreeItemRoot .GroupLabel {
  display: flex;
  align-items: center;
}
.GroupTreeItemRoot .GroupRootLabel {
  font-weight: bold;
  color: var(--header-font-color);
  fill: var(--header-font-color);
}
.GroupTreeItemRoot .GroupLabel .groupLabelIcon {
  margin-right: 0.5rem;
}

.GroupTreeItemRoot .ChildTree {
  margin: 0.5rem 0 0 2rem;
}
.GroupTreeItemRoot .ChildTree.close {
  display: none;
}

.GroupTreeNode {
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
}
.GroupTreeNode.odd {
  background-color: var(--record2-bg-color);
}
.GroupTreeNode.even {
  background-color: var(--record1-bg-color);
}
.GroupTreeNode.disabled {
  background-color: #8d8d94; /* 実装時に定義する */
}
.GroupTreeNode.selected {
  outline: var(--basic-outline-style);
}
.GroupTreeNode .GroupLabel span {
  line-height: 1rem;
}
.GroupTreeNode .GroupToggle {
  transition-duration: 100ms;
}
.GroupTreeNode .GroupToggle:not(.open) {
  transform: rotate(-90deg);
}
/* 内包するグループについて、いずれも検索ワードに一致していなければ非活性色とする */
.GroupTreeNode.unmatched:has(.GroupTreeNode.unmatched, .GroupTreeEdge.unmatched):not(:has(.GroupTreeNode.matched, .GroupTreeEdge.matched))
  .GroupLabel {
  color: var(--basic-bg-color-disabled);
}
/* 内包するグループについて、いずれかが検索ワードに一致していれば通常色とする */
.GroupTreeNode.unmatched:not(:has(.GroupTreeNode.unmatched, .GroupTreeEdge.unmatched)):not(:has(.GroupTreeNode.matched, .GroupTreeEdge.matched))
  .GroupLabel {
  color: var(--basic-font-color);
}

.GroupTreeEdge {
  padding: 0.5rem;
  min-height: 1.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.GroupTreeEdge.odd {
  background-color: var(--record2-bg-color);
}
.GroupTreeEdge.even {
  background-color: var(--record1-bg-color);
}
.GroupTreeEdge.disabled {
  background-color: #8d8d94; /* 実装時に定義する */
}
.GroupTreeEdge.selected {
  outline: var(--basic-outline-style);
}
.GroupTreeEdge span {
  line-height: 1.6rem;
}
.GroupTreeEdge.unmatched {
  color: var(--basic-bg-color-disabled);
}

.GroupTreeEditPane {
  padding: 0 0.5rem;
}
.GroupLabel:not(:hover) .GroupTreeEditPane {
  display: none;
}
.GroupTreeEdge:not(:hover) .GroupTreeEditPane {
  display: none;
}
