.CroppedViewer .viewer-control-area {
  z-index: 2;
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
}

.CroppedViewer .viewer-control-area .divider {
  padding: 0;
  margin: 0.25rem 0;
  border: none;
}

.CroppedViewer .view-area {
  border: 1px solid var(--viewer-border-color);
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  user-select: none;
}

.CroppedViewer .view-area .src {
  display: none;
}
.CroppedViewer .view-area .document {
  width: 100%;
  object-fit: contain;
  display: block;
}
