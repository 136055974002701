/* ListView */
.FilterView {
  display: flex;
}

/* FilterStatusView */
.FilterStatusView {
  position: relative;
  display: grid;
  height: 1.3rem;
  width: 60%;
  padding: 0.5rem 2.5rem 0.5rem 0.6rem;
  line-height: 1.4rem;
  background-color: var(--input-bg-color-disabled);
  border-radius: 0 0.3rem 0.3rem 0rem;
  overflow: hidden;
  align-items: center;
  word-break: break-all;
}

.FilterStatusView:has(+ .FilterSave) {
  width: 50%;
}

.FilterStatusView.on {
  height: auto;
  min-height: 1.3rem;
}

.FilterStatusView.on .aid svg {
  transform: scale(1, -1);
}

.FilterStatusView .text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.FilterStatusView.on .text {
  white-space: pre-wrap;
}

.FilterStatusView .aid {
  display: inline-block;
  position: absolute;
  top: 0.2em;
  right: 1em;
  height: 1em;
  width: 1em;
  cursor: pointer;
}

.FilterStatusView .aid svg {
  width: 1.4em;
  fill: var(--basic-icon-color);
}

/* FilterButton */
.FilterButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.7rem;
  width: 6.96rem;
  padding: 0.284rem;
  font-size: var(--basic-small-font-size);
  background-color: var(--button-bg-color);
  border-radius: 0.3rem 0 0 0.3rem;
  color: var(--button-font-color);
}

.FilterButton .filter-btn-color {
  width: 1em;
  fill: var(--button-font-color);
}

.FilterButton svg {
  display: inline-block;
  margin-right: 0.2rem;
}
