.Radio .message {
  color: var(--input-font-color-error);
  font-size: var(--input-error-font-size);
}

.Radio label:has(+ label) {
  margin-right: 5rem;
}

.Radio label.vertical {
  display: block;
  margin-bottom: 1rem;
}
