html:has(.UISample) {
  scroll-behavior: smooth;
}
.UISample .categories {
  margin-bottom: 3em;
}
.UISample h2 {
  border-bottom: 1px solid var(--basic-line-color);
}

.UISample h3:not(.input-blocktitle, .area-title, .accordion-title-label) {
  border-left: 0.5em solid var(--basic-line-color);
  padding-left: 0.5em;
  box-sizing: border-box;
}

.UISample h4 {
  margin-bottom: 0.5em;
}

.UISample .iconVariations {
  margin: 1em 0 2em 0.5em;
}
.UISample .iconVariations > div {
  padding: 0 0.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
}
.UISample .iconVariations span {
  margin-bottom: 0.5em;
}

.UISample .output {
  color: var(--basic-font-color);
  background-color: var(--input-bg-color-disabled);
  padding: 0.7em 0.5em;
  margin: 0 0 2em;
  border-radius: 4px;
  position: relative;
  min-height: calc(var(--input-box-height, 2.5rem) * 2);
  box-sizing: border-box;
  overflow-x: auto;
}
.UISample :has(> .output) {
  position: relative;
}
.UISample :has(> .output)::before {
  content: '入力結果';
  position: absolute;
  margin-top: -0.3rem;
  font-size: 0.5rem;
  z-index: 1;
}
.UISample .sample-wrapper {
  border: 1px dashed var(--input-bg-color-disabled);
  border-radius: 4px;
  padding: 1em;
  box-sizing: border-box;
}
.UISample .sample-inner {
  background-color: var(--input-bg-color-disabled);
  padding: 1em;
  box-sizing: border-box;
}

.UISample .custom-dnd-area {
  color: var(--input-font-color);
  border: 2px dashed var(--basic-line-color);
  background-color: var(--basic-bg-color);
}
.UISample .partial-loading-area {
  width: 20rem !important; /* サンプルなので許容しているが !important を他所で使わないこと */
  height: 10rem;
  border: 1px dashed #ccc;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  align-items: center;
}

/* ページ内ナビゲーション */
.UISample .floatNavigation {
  position: fixed;
  bottom: 0rem;
  right: 4.3rem; /* ツールバーの分ずらす */
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: #fff6;
  border: 1px solid var(--input-frame-color);
  border-bottom: none;
  overflow: hidden;
  z-index: 1;
}
.UISample .floatNavigation:not(.show) {
  border-width: 0;
  background-color: transparent;
}
.UISample .floatNavigation li {
  margin: 0.5rem 0;
}
.UISample .page-nav-button {
  width: auto;
  border-radius: 0;
}
.UISample .floatNavigation:not(.show) .page-nav-button {
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}
.UISample .page-nav-list {
  list-style: none;
  margin-block: 0;
  padding-inline: 0;
  padding: 1rem;
  overflow: scroll;
  max-height: calc(100vh - 8rem); /* ボタン&ヘッダ分(ざっくり)を引く */

  transition-duration: 100ms;
}
.UISample .floatNavigation:not(.show) .page-nav-list {
  width: 0;
  max-height: 0;
  padding: 0;
}
