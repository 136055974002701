.BlueprintAttributeConfirm {
  border: 1px solid var(--basic-line-color);
}

.BlueprintAttributeConfirm .title {
  padding: 1rem;
  background-color: var(--listheader-bg-color);
  color: var(--listheader-font-color);
}
.BlueprintAttributeConfirm .body {
  padding: 1rem;
}

.BlueprintAttributeConfirm .SimpleListView {
  margin: 1rem 0;
}

.BlueprintAttributeConfirm .actions {
  display: flex;
  gap: 1rem;
}
.BlueprintAttributeConfirm .actions .CaptionButton {
  min-width: 45%;
}
