.CommentPaneForum {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 1.8rem;
}
.CommentPaneForum > .IconButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.9rem;
  padding: 0.5em;
  background-color: var(--viewer-border-color);
  border-radius: 0 15px 15px 0;
  cursor: pointer;
}
.CommentPaneForum .list-container {
  position: relative;
  scroll-behavior: smooth;
  border-color: var(--viewer-border-color);
  border-style: solid;
  border-width: 0;
  box-sizing: border-box;
  transition-duration: 300ms;
  transition-property: padding, opacity, width, height, border-width;
  overflow-x: hidden;
  overflow-y: auto;
  opacity: 0;
  width: 0;
  height: 0;
}
.CommentPaneForum .list-container.show {
  border-top-width: 1px;
  border-right-width: 1px;
  width: calc(100% - 2.5rem);
  opacity: 1;
  height: calc(100dvh - 5.5rem);
}
