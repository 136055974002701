.Viewer .controls > svg {
  fill: var(--viewer-icon-color);
}

.Viewer .controls .arrow-icon {
  fill: var(--viewer-page-icon-color);
}

.Viewer .controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.25rem;
  margin: 0.5rem 0 0.2rem;
}

.Viewer .controls .filename {
  font-weight: bold;
  width: calc(100% - 12rem);
  text-align: left;
}
.Viewer .controls .pager {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.Viewer .viewer-control-area {
  z-index: 2;
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
}

.Viewer .viewer-control-area .divider {
  padding: 0;
  margin: 0.25rem 0;
  border: none;
}

.Viewer .view-area {
  border: 1px solid var(--viewer-border-color);
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  user-select: none;
}
.Viewer .view-area .document {
  width: 100%;
  height: 100%;
  display: block;
}
.Viewer .view-area .document.move,
.Viewer .view-area .document.selection.flip {
  cursor: grab;
}
.Viewer .view-area .document.move:active,
.Viewer .view-area .document.selection.flip:active {
  cursor: grabbing;
}
.Viewer .view-area .document.move.flip,
.Viewer .view-area .document.selection {
  cursor: crosshair;
}

.Viewer .overlay {
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  opacity: 1;
  transition-duration: 150ms;
  transition-property: opacity;
}
.Viewer .overlay.dragging {
  opacity: 0;
}
