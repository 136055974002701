.Notice {
  padding: 0.2rem;
  height: 5rem;
}
.Notice .notice-header {
  display: flex;
  justify-content: space-between;
}
.Notice .notice-header .notice-title {
  font-weight: bold;
}

.Notice .notice-content {
  width: 20rem;
  height: 2.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
}

.Notice {
  background-color: var(--record1-bg-color);
}

.Notice:nth-child(odd) {
  background-color: var(--record2-bg-color);
}

.Notice.disabled {
  display: none;
}

.Notice .open-icon {
  float: right;
}
