.DragModeSelector {
  display: flex;
  flex-direction: column;
}
.DragModeSelector .selector-wrapper {
  position: relative;
}
.DragModeSelector .selector-bg {
  position: fixed;
  width: 100dvw;
  height: 100dvh;
  top: 0;
  left: 0;
  z-index: 1;
}
.DragModeSelector .selector {
  display: flex;
  position: absolute;
  right: 1.5rem;
  top: 0;
  z-index: 1;
  overflow-x: hidden;
  width: 0;

  transition-duration: 150ms;
  transition-property: width;
}
.DragModeSelector .selector.show {
  z-index: 3;
  width: 3rem;
}
