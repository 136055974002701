.WorkTaskCatalogInput .line-height-4rem {
  line-height: 4rem;
}

.WorkTaskCatalogInput .side-search-icon {
  margin-top: 1.5rem;
}

.WorkTaskCatalogInput .input-line .item-group-100.no-space {
  gap: 0 0;
}

.WorkTaskCatalogInput .input-line .item-group-100 .item-group-100.no-space {
  width: inherit;
}
