.noscroll .ListView .contents {
  overflow: hidden;
}

.ListView .FilterView {
  padding: 0.5rem 0 1rem;
}

.ListView ul {
  margin: 0;
  padding: 0;
}

.ListView .list-header {
  padding: 0 0 0.7rem;
}

.ListView .icon-list-footer {
  min-height: 3rem;
  max-height: var(--footer-max-height, 10rem);
  position: fixed;
  z-index: 2;
  left: 0;
  bottom: 0;
  width: calc(100vw - var(--toolbar-width));
  margin: auto;
  background-color: var(--footer-bg-color);
  box-shadow: 0 -0.1rem var(--footer-border-color);
  /*
  横幅でボタン溢れる場合はスクロール
  display:flexにする場合は以下を削除
  */
  overflow-x: auto;
}

.ListView .icon-list-footer > .footer-contents,
.ListView .list-footer > .footer-contents {
  width: 85vw;
  margin: 0.8rem auto;
  display: flex;
  justify-content: space-between;
}

.ListView .icon-list-footer,
.ListView .list-footer {
  padding: 0.4rem 0;
}

.ListView .list-footer-button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ListView .list-footer-button .button-area {
  margin: 0 0.5rem;
}

.ListView .footer-information {
  margin-top: 0.8rem;
}

.ListView .navgate {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.ListView .navgate .icon-area {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0 0 0 0;
  margin-right: auto;
}

.ListView .navgate select {
  width: 6rem;
  padding: 0.35rem 0;
  text-align: center;
  color: var(--input-font-color);
  appearance: none;
  border: 1px solid var(--pulldown-frame-color);
}

.ListView .pages-btns {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0 0 0 2.76rem;
}

/* 後日削除予定 */
.ListView .navgate .iconBox .icon {
  height: 1.65rem;
  width: 1.65rem;
  margin-right: 1.4rem;
  border-radius: 0.2rem;
}

.ListView .navgate .iconBox:nth-last-child(1) .icon:not(.normal) {
  height: 2.04rem;
  width: 2.04rem;
  margin-right: 0;
}
.ListView .navgate .iconBox:nth-last-child(2) .icon:not(.normal) {
  height: 2.04rem;
  width: 2.04rem;
  margin-right: 0.7rem;
}

.ListView .navgate .iconBox:nth-last-child(3) .icon {
  margin-right: 1.5rem;
}

.ListView .navgate .iconBox .cancel {
  background-color: var(--listreloadbutton-bg-color);
  color: var(--listreloadbutton-font-color);
}

.ListView .navgate .iconBox .basic {
  background-color: var(--downloadbutton-bg-color);
  color: var(--downloadbutton-font-color);
}
/* 後日削除予定ここまで */

.ListView .navgate .list-icon {
  height: 1.65rem;
  width: 1.65rem;
  margin-right: 1.4rem;
  border-radius: 0.2rem;
  fill: var(--basic-icon-color);
}

.ListView .navgate .list-icon:nth-last-child(1) {
  width: 2.04rem;
  margin-right: 0;
}

.ListView .navgate .list-icon:nth-last-child(3) {
  margin-right: 1.5rem;
}

.ListView .count-number {
  display: flex;
  align-items: center;
  height: 1.3rem;
}

.ListView .count-number .unit {
  display: inline-block;
  padding: 0 0 0 0.64rem;
}

.ListView .page-counter {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1.42rem 0.2rem 0;
}

.ListView .count-number .label {
  position: relative;
  height: 1.8rem;
  display: block;
  margin: 0 0 0 0.3rem;
  background-color: var(--input-bg-color);
  border-radius: 0.3em;
}

.ListView .Select .single::after {
  border-color: var(--pulldown-frame-color);
}

.ListView .count-number .unit {
  display: inline-block;
}

.ListView .contents {
  min-height: 17.36rem;
  max-height: 70vh;
  overflow-y: scroll;
}

.ListView .content .check {
  max-width: 80px;
}

.ListView .Checkbox .margin {
  margin: 0 0 0.5rem 0;
}

.ListView .list-caption {
  background-color: var(--listheader-bg-color);
  min-width: fit-content;
  position: sticky;
  top: 0;
  z-index: 1;
}

.ListView .list-caption .list-header-cell svg {
  fill: var(--listheader-font-color);
}

.ListView .list-header-cell {
  display: flex;
  align-items: center;
  height: var(--listheader-height);
  padding: 0 0.4rem;
  color: var(--listheader-font-color);
  box-sizing: border-box;
}

/* Menu */

.ListView div.burgermenu {
  /* margin-bottom: 13em; */
  position: relative;
  max-width: 5.2rem;
  width: 100%;
  padding: 0;
  /* background-color: #444; */
}

.ListView .menuIcon {
  pointer-events: none;
}

/* burgermenu */
.ListView .burgermenu ul {
  margin: 0;
  padding: 0;
}

.ListView .burgermenu ul > li > a {
  display: block;
  width: 100%;
  height: var(--listitem-height, 2.8rem);
  text-transform: uppercase;
  letter-spacing: 0.2em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  box-sizing: border-box;
}

.ListView .burgermenu .list .menu-box {
  display: inline-block;
}

.ListView .burgermenu svg {
  height: var(--listitem-icon-height, 1.7rem);
  width: var(--listitem-icon-width, 1.7rem);
  fill: var(--input-font-color);
}

.ListView .list-header-cell.burgermenu svg {
  height: var(--listheader-icon-height, 1.4rem);
  width: var(--listheader-icon-width, 1.4rem);
}

.ListView .burgermenu .list .menu-box > .menu-list {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 4rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  transition: opacity 0.2s linear;
  width: 250px;
  margin: 0;
}

.ListView .burgermenu .list .menu-box:hover.open > .menu-list {
  visibility: visible;
  opacity: 1;
  max-height: var(--menu-list-max-height, 200px);
  width: var(--menu-list-width, 500px);
  z-index: 1;
}

.ListView .burgermenu .list .menu-box > a {
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  width: 4rem;
  height: var(--listitem-height);
  color: var(--input-font-color);
}

.ListView .burgermenu .list .menu-box:hover.open > a {
  background-color: var(--sendbutton-bg-color);
  color: var(--button-font-color);
  border: 0.5px solid var(--menu-font-color-hover);
  box-sizing: border-box;
}

.ListView .list-header-cell.burgermenu .list .menu-box > a {
  height: var(--listheader-height);
}

.ListView .burgermenu .list .menu-box:hover.open svg {
  fill: var(--input-font-color);
  opacity: 0.5;
}

.ListView .list-content .burgermenu .list .menu-box:hover.open svg {
  animation: rotate 0.1s forwards;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-90deg);
  }
}

/* Submenu */
.ListView .burgermenu .menu-list > .menu-item {
  position: relative;
  display: block;
  height: var(--listitem-height, 2.8rem);
  max-width: 50%;
  width: 100%;
  box-sizing: border-box;
}

.ListView .burgermenu .menu-list > .menu-item > a {
  border: 0.5px solid var(--menu-font-color-hover);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0.4rem;
  background-color: var(--actionmenu-bg-color);
  color: var(--actionmenu-font-color);
  text-decoration: none;
}

.ListView .burgermenu .menu-list > .menu-item > a:hover {
  background-color: var(--actionmenu-bg-color-hover);
  color: var(--actionmenu-font-color-hover);
}

.ListView .burgermenu .menu-list .empty {
  display: none;
}

.ListView .listcolor.even {
  background-color: var(--record1-bg-color);
  color: var(--record1-font-color);
}

.ListView .listcolor.odd {
  background-color: var(--record2-bg-color);
  color: var(--record2-font-color);
}

.ListView .item.even {
  background-color: var(--record1-bg-color);
  color: var(--record1-font-color);
}

.ListView .item.odd {
  background-color: var(--record2-bg-color);
  color: var(--record2-font-color);
}

.ListView .listcolor.selectedItem,
.ListView .listcolor.selectedItem .item {
  background-color: var(--record-bg-color-selected);
  color: var(--record-font-color-selected);
}

.ListView .list-icon:hover .icon-description,
.ListView .header-icon:hover .icon-description,
.pageNavigation .navi-icon:hover .icon-description {
  display: block;
  cursor: pointer;
}

.ListView .list-icon .icon-description:before,
.ListView .header-icon .icon-description:before,
.pageNavigation .navi-icon .icon-description:before {
  content: '';
  position: absolute;
  top: -24px;
  left: 15%;
  border: 15px solid transparent;
  border-top: 15px solid var(--input-frame-color);
  margin-left: -15px;
  transform: rotateZ(180deg);
}

.ListView .item:hover .description {
  display: inline-block;
  top: 30px;
  left: 0px;
}

.ListView .viewText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ListView .item:hover .viewText:empty + .description {
  display: none;
}

.ListView .icon-description,
.ListView .description,
.pageNavigation .icon-description {
  display: none;
  position: absolute;
  z-index: 2;
  padding: 10px;
  font-size: var(--basic-small-font-size);
  line-height: 1.6rem;
  color: var(--input-font-color);
  overflow-wrap: break-word;
  border-radius: 0.3em;
  background: var(--input-frame-color);
  width: 100px;
}

.ListView .description:before {
  content: '';
  position: absolute;
  top: -24px;
  right: 60%;
  border: 15px solid transparent;
  border-top: 15px solid var(--input-frame-color);
  margin-left: -15px;
  transform: rotateZ(180deg);
}

.ListView .item {
  position: relative;
  display: flex;
  align-items: center;
  height: var(--listitem-height);
  padding: 0.5rem;
  box-sizing: border-box;
}

.ListView .check .Checkbox {
  display: flex;
  justify-content: center;
}

.ListView .total {
  background-color: var(--listsum-bg-color);
  color: var(--record1-font-color);
}
