.license-add-dialog .checkbox-lineup {
  padding-top: 1.5rem;
}

.LicenseAddDialog > .Dialog > .modal-content {
  min-width: 45rem;
  max-width: 45rem;
  min-height: 30rem;
}

.LicenseAddDialog .license-add-dialog {
  height: 100%;
  box-sizing: border-box;
}
.LicenseAddDialog .detail-area {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
