.ViewerControlButton {
  background-color: var(--viewer-icon-bg-color);
  color: var(--viewer-icon-color);
  fill: var(--viewer-icon-color);
  cursor: pointer;
  border-radius: 0;
  font-size: 0.75rem;
}

.ViewerControlButton:hover:not([disabled]) {
  opacity: 0.8;
}

.ViewerControlButton.active {
  background-color: var(--basic-line-color);
}
