.help-right {
  width: 24rem;
  margin: 0.8rem;
}

.help-right .tabPanelContents {
  margin-bottom: 1rem;
}
.help-right .tabPanel {
  cursor: pointer;
}
.help-right .tab-contents {
  margin: 1.5rem 1.5rem 0;
}

.help-right .search-area .search-btn {
  padding-bottom: 1.1rem;
}

.help-right .search-area .search-btn svg {
  width: 2.5rem;
  height: 2rem;
}

.help-right .navTOHelpTop {
  margin-top: 2rem;
}

.search-area {
  display: flex;
}

.help-right .manual .viewTitle {
  margin-top: 0;
  font-weight: 700;
}

.help-right .manual .manual-contents {
  margin-left: 1rem;
}

.help-right .manual .manual-contents .manual-list {
  margin-left: 1rem;
}

.help-right .manual .manual-contents .manual-list .manual-item {
  margin-top: 1rem;
}
