.OrganizationMemberDialog .Dialog .modal-content {
  width: 60%;
  max-width: 60%;
}

.OrganizationMemberDialog .Radio {
  margin-left: 1rem;
}

.OrganizationMemberDialog .add-button {
  padding-top: 0.8rem;
}

.OrganizationMemberDialog .parents-breadcrumb {
  margin-bottom: 1rem;
  font-size: 0;
}
.OrganizationMemberDialog .parents-breadcrumb > span {
  display: inline-flex;
  align-items: center;
}
.OrganizationMemberDialog .parents-breadcrumb > span:last-child {
  display: flex;
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}
.OrganizationMemberDialog .parents-breadcrumb > span:not(:last-child) {
  font-size: 0.9rem;
  margin-bottom: 0.25rem;
}
.OrganizationMemberDialog .parents-breadcrumb > span:not(:last-child)::after {
  content: '>';
  padding: 0 0.25rem;
  font-weight: bold;
  font-size: 0.7rem;
  color: var(--deletebutton-bg-color);
}
