.DataGrid {
  display: 'flex';
  flex-direction: 'column';
}

.DataGrid .header {
  background-color: var(--listheader-bg-color);
  color: var(--listheader-font-color);
}
.DataGrid .even {
  background-color: var(--record1-bg-color);
}
.DataGrid .odd {
  background-color: var(--record2-bg-color);
}
