.SegmentEditDialog > .Dialog > .modal-content {
  max-width: 80dvw;
  max-height: 80dvh;
}
.SegmentEditDialog .Checkbox > .ErrorMessage {
  display: none;
}
.SegmentEditDialog .actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.SegmentEditDialog .Rect {
  pointer-events: none;
}

.SegmentEditDialog .former-plot {
  border-style: dashed;
}
