/* CSS変数定義　参考資料は「デザイン資料[M-Tech Navi]」 */
:root [theme='dark'] {
  /* 基本色 */
  --basic-bg-color: var(--main-color-blue-brown);
  /* 背景色 */
  --basic-bg-color-hover: var(--secondly-color-blue-gray);
  /* 背景色 hover&click */

  --basic-font-color: var(--secondly-color-white-gray);
  /* 文字色 */
  --basic-line-color: var(--secondly-color-white-gray);
  /* 罫線 */

  /* フォーカス時などのアウトライン色 */
  --outline-color: var(--main-color-light-blue-green);

  --basic-icon-color: var(--secondly-color-white-gray);
  /* ボタンアイコン */
  /* --basic-icon-color-hover: var(--secondly-color-gray); */
  /* ボタンアイコン hover&click */

  --header-bg-color: var(--secondly-color-blue-green);
  /* --header-bg-color-hover: var(--main-color-green-gray); */
  /* hover&click */

  --header-font-color: var(--main-color-white-smoke);

  --header-icon-color: var(--main-color-white-smoke);
  --header-icon-color-hover: var(--secondly-color-blue-gray);
  /* hover&click */

  --dialog-bg-color: var(--main-color-blue-brown);
  --dialog-font-color: var(--secondly-color-white-gray);
  --dialog-frame-color: var(--main-color-gray);
  --dialogheader-bg-color: var(--main-color-green);
  --dialogheader-font-color: var(--main-color-white-smoke);
  /* --scrollbar-color: var(--secondly-color-white-gray); */
  --basic-bg-color-disabled: rgba(80, 80, 90, 0.65);

  --button-bg-color: var(--secondly-color-light-green);
  --button-bg-color-hover: var(--main-color-light-blue-green);
  /* hover&click */

  --button-font-color: var(--secondly-color-white-gray);
  /* --button-font-color-hover: var(--secondly-color-white-gray); */
  /* hover&click */

  --highbutton-bg-color: var(--main-color-white-smoke);
  --highbutton-bg-color-hover: var(--main-color-green);
  /*  hover&click */

  --highbutton-font-color: var(--secondly-color-blue-gray);
  --highbutton-font-color-hover: var(--main-color-white-smoke);
  /*  hover&click */

  --button-bg-color-disabled: var(--main-color-blue-brown2);
  --button-font-color-disabled: var(--main-color-blue-gray);
  /* --button-image-color-disabled: #000; */
  /* TODO 画像 50&透過で指定する。#000はダミー */
  --input-bg-color: var(--main-color-light-crow-black);
  --input-font-color: var(--secondly-color-white-gray);
  --input-frame-color: var(--secondly-color-blue-purple);

  --input-icon-color: var(--secondly-color-white-gray);
  /* --input-icon-color-hover: var(--secondly-color-gray); */
  /* hover&click */

  --input-bg-color-disabled: var(--main-color-crow-black);
  --input-font-color-disabled: var(--main-color-blue-gray2);
  --input-frame-color-disabled: var(--secondly-color-white-gray);
  --input-bg-color-disabled-hover: var(--secondly-color-blue-purple2);
  /* --input-bg-color-error: var(--main-color-white); */
  --input-font-color-error: var(--main-error-color-pink);
  --input-frame-color-error: var(--main-error-color-pink);
  --input-icon-color-error: var(--main-error-color-pink);
  /* --titlelabel-bg-color: var(--main-color-blue-brown); */
  --titlelabel-font-color: var(--main-color-white-smoke);

  --link-font-color: var(--main-color-light-blue2);
  --link-font-color-hover: var(--secondly-color-light-green);
  /* hover&click */

  /* --sideber-bg-color: var(--main-color-light-crow-black);
  --sideber-bg-color-hover: var(--main-color-light-green-gray); */
  /* hover&click */

  /* --sideber-icon-color: var(--main-color-light-crow-black); */
  /* ボタンアイコン */
  /* --sideber-icon-color-hover: var(--secondly-color-blue-gray); */
  /* ボタンアイコン hover&click */

  --tool-icon-color: var(--secondly-color-light-blue-green);
  /* ツールアイコン */
  --tool-disabled-icon-color: var(--secondly-color-blue-gray);
  /* ツールアイコン（不活性時） */
  /* --tool-read-button-bg-color: var(--secondly-color-blue-gray); */
  /* ボタンアイコン */

  --footer-bg-color: var(--main-color-crow-black);
  --footer-border-color: rgba(0, 0, 0, 0.16);

  /* 個別色 */
  --label-bg-color: var(--main-color-white-gray);
  /* --label-font-color: var(--secondly-color-white-gray); */
  --checkbox-bg-color: var(--main-color-light-crow-black);
  --checkbox-frame-color: var(--secondly-color-blue-purple);
  --checkbox-check-color: var(--secondly-color-white-gray);
  --checkbox-bg-color-disabled: var(--main-color-crow-black);
  --checkbox-frame-color-disabled: var(--secondly-color-white-gray);
  --checkbox-check-color-disabled: var(--main-color-blue-gray2);
  --pulldown-bg-color: var(--main-color-light-crow-black);
  --pulldown-font-color: var(--secondly-color-white-gray);
  --pulldown-frame-color: var(--secondly-color-blue-purple);
  --pulldown-icon-color: var(--secondly-color-white-gray);
  /* ボタンアイコン */
  --pulldown-searcharea-bg-color: var(--secondly-color-blue-gray);
  --pulldown-resultarea-bg-color: var(--secondly-color-blue-gray);
  --pulldown-resultarea-bg-color-hover: var(--main-color-blue-brown);

  --listheader-bg-color: var(--main-color-green);
  --listheader-font-color: var(--main-color-white-smoke);

  --record1-bg-color: var(--main-color-blue-brown);
  --record1-bg-color-hover: var(--secondly-color-blue-gray);
  /* hover&click */

  --record1-font-color: var(--secondly-color-white-gray);

  --record2-bg-color: var(--main-color-light-crow-black);
  /* --record2-bg-color-hover: var(--secondly-color-blue-gray); */
  /* hover&click */

  --record2-font-color: var(--secondly-color-white-gray);

  --record-bg-color-selected: var(--main-color-crow-black);
  --record-font-color-selected: var(--main-color-blue-gray2);

  --listsum-bg-color: var(--main-color-crow-black);
  /* --listsum-font-color: var(--secondly-color-white-gray); */
  --group-bg-color: var(--secondly-color-light-green);
  /* --group-font-color: var(--main-color-white-smoke);
  --group-line-color: var(--secondly-color-light-green); */
  --toast-bg-color: var(--secondly-color-light-green);
  --toast-font-color: var(--secondly-color-white-gray);
  --errortoast-bg-color: var(--secondly-error-color-orange-red);
  --errortoast-font-color: var(--secondly-color-white-gray);
  --warningtoast-bg-color: var(--main-warning-color-orange);
  --warningtoast-font-color: var(--main-color-white-smoke);

  --menu-bg-color: var(--main-color-light-crow-black);
  --menu-bg-color-hover: var(--main-color-light-blue-green);
  /* hover&click */

  --menu-font-color: var(--secondly-color-white-gray);
  --menu-font-color-hover: var(--secondly-color-white-gray);
  /* hover&click */

  /* --menu-bg-color-selected: var(--secondly-color-light-green); */

  --actionmenu-bg-color: rgba(11, 18, 36, 0.9);
  --actionmenu-bg-color-hover: var(--secondly-color-blue-gray-2);
  /* hover&click */

  --actionmenu-line-color: var(--main-color-light-crow-black);
  /* 罫線 */

  --actionmenu-font-color: var(--secondly-color-white-gray);
  --actionmenu-font-color-disabled: var(--secondly-color-white-gray) 75;
  --actionmenu-font-color-hover: var(--secondly-color-white-gray);
  /* hover&click */

  --popupmenu-bg-color: var(--main-color-light-crow-black);
  --popupmenu-bg-color-hover: var(--main-color-blue-brown);
  /* hover&click */

  --popupmenu-font-color: var(--secondly-color-white-gray);
  /* --popupmenu-font-color-hover: var(--secondly-color-white-gray); */
  /* hover&click */

  --card-bg-color: var(--main-color-light-crow-black);
  /* --card-font-color: var(--secondly-color-white-gray);
  --tag-bg-color: var(--secondly-color-blue-gray);
  --tag-font-color: var(--main-color-white-smoke); */

  --deletebutton-bg-color: var(--secondly-color-night-blue);
  --deletebutton-bg-color-hover: var(--secondly-color-blue-gray-3);
  /*  hover&click */

  --deletebutton-font-color: var(--secondly-color-white-gray);
  /* --deletebutton-font-color-hover: var(--secondly-color-white-gray); */
  /*  hover&click */

  --backbutton-bg-color: var(--secondly-color-blue-gray-4);
  --backbutton-bg-color-hover: var(--secondly-color-blue-purple2);
  /*  hover&click */

  --backbutton-font-color: var(--secondly-color-white-gray);
  /* --backbutton-font-color-hover: var(--secondly-color-white-gray); */
  /*  hover&click */

  --subbutton-bg-color: #var(--secondly-color-blue-green2);
  --subbutton-bg-color-hover: var(--secondly-color-light-blue-green);
  /*  hover&click */

  --subbutton-font-color: var(--secondly-color-white-gray);
  /* --subbutton-font-color-hover: var(--secondly-color-white-gray); */
  /* hover&click */

  /* --alreadyreadbutton-bg-color: var(--secondly-color-blue-gray); */
  /* --alreadyreadbutton-bg-color-hover: var(--secondly-color-ash-green); */
  /*  hover&click */

  /* --alreadyreadbutton-font-color: var(--main-color-white-smoke);
  --alreadyreadbutton-font-color-hover: var(--main-color-white-smoke); */
  /*  hover&click */

  --sendbutton-bg-color: #var(--secondly-color-blue-green2);
  /* --sendbutton-bg-color-hover: var(--secondly-color-light-blue-green); */
  /*  hover&click */

  /* --sendbutton-font-color: var(--secondly-color-white-gray);
  --sendbutton-font-color-hover: var(--secondly-color-white-gray); */
  /*  hover&click */

  --ssobutton-bg-color: var(--main-color-light-crow-black);
  /* --ssobutton-bg-color-hover: var(--main-color-light-crow-black); */
  /* TODO 現状同じ色だが変更されそうなので変数定義 hover&click */

  --ssobutton-frame-color: var(--secondly-color-blue-purple);
  /* 枠線 */
  --ssobutton-frame-color-hover: var(--secondly-color-light-purple2);
  /*  hover&click */

  --ssobutton-font-color: var(--secondly-color-white-gray);
  /* --ssobutton-font-color-hover: var(--main-color-white-smoke); */
  /*  hover&click */

  --ssobutton-image-color: #fff;
  /*TODO 画像 100%で指定。#fffはダミー */
  --ssobutton-image-color-hover: #000;
  /*　TODO hover&click 50&透過で指定する。#000はダミー */

  /* --profilebutton-bg-color: var(--main-color-white-smoke);
  --profilebutton-bg-color-hover: var(--main-color-green); */
  /*  hover&click */

  /* --profilebutton-font-color: var(--secondly-color-blue-gray);
  --profilebutton-font-color-hover: var(--main-color-white-smoke); */
  /*  hover&click */

  --listreloadbutton-bg-color: var(--secondly-color-blue-green);
  /* --listreloadbutton-bg-color-hover: var(--main-color-green-gray); */
  /*  hover&click */

  --listreloadbutton-font-color: var(--main-color-white-smoke);
  --listreloadbutton-font-color-hover: var(--main-color-light-gray);
  /*  hover&click */

  --downloadbutton-bg-color: var(--secondly-color-gray);
  --downloadbutton-bg-color-hover: var(--main-color-gray);
  /*  hover&click */

  --downloadbutton-font-color: var(--main-color-blue-brown);
  /* --downloadbutton-font-color-hover: var(--secondly-color-gray2); */
  /*  hover&click */

  /* --pageheaderhmbutton-bg-color: var(--main-color-white-smoke);
  --pageheaderhmbutton-bg-color-hover: var(--main-color-green); */
  /*  hover&click */

  /* --listheaderbutton-font-color: var(--main-color-white-smoke);
  --listheaderbutton-font-color-hover: var(--secondly-color-blue-gray); */
  /*  hover&click */

  /* --listhmbutton-font-color: var(--main-color-white-smoke);
  --listhmbutton-font-color-hover: var(--secondly-color-blue-gray); */
  /*  hover&click */

  /* --listhmbutton1-bg-color: var(--main-color-blue-brown);
  --listhmbutton1-bg-color-hover: var(--main-color-light-green-gray); */
  /*  hover&click */

  /* --listhmbutton2-bg-color: var(--main-color-light-crow-black);
  --listhmbutton2-bg-color-hover: var(--main-color-light-green-gray); */
  /*  hover&click */

  --accent-bg-color: var(--secondly-color-blue-green);
  --accent-font-color: var(--secondly-color-light-green);
  --accent-icon-color: var(--main-color-light-blue2);
  /* アイコン */

  --loading-icon-color: #fff;

  --fileviewer-list-contents-bg-color-selected: var(
    --main-color-light-blue-green
  );
  --fileviewer-list-contents-bg-color: var(--secondly-color-light-green);
  --fileviewer-list-contents-bg-color-hover: var(--secondly-color-blue-green);
  --fileviewer-list-font-color: var(--main-color-white);
  --fileviewer-list-header-bg-color: var(--main-color-green);
  /* --fileviewer-icon-color: var(--main-color-white); */

  --viewer-border-color: var(--secondly-color-blue-purple);
  --viewer-icon-bg-color: var(--main-color-crow-black);
  --viewer-icon-color: var(--main-color-white);
  --viewer-page-icon-color: var(--secondly-color-white-gray);

  --file-upload-dnd-bg-color: var(--secondly-color-blue-gray-4);
}
