.WtWorkTaskInput .line-height-4rem {
  line-height: 4rem;
}

.WtWorkTaskInput .file-area {
  margin-bottom: 2rem;
}

.WtWorkTaskInput .mt-3 {
  margin-top: 3rem;
}

.WtWorkTaskInput .side-search-icon {
  margin-top: 1.5rem;
}

.WtWorkTaskInput .input-line .item-group-100.no-space {
  gap: 0 0;
}

.WtWorkTaskInput .input-line .item-group-100 .item-group-100.no-space {
  width: inherit;
}
