.businessUnitManagementList .ListView .footer-contents {
  width: calc(100vw - var(--toolbar-width, 5vw) * 2);
  float: right;
}
.businessUnitManagementList .ListView .list-footer-button {
  width: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
