.help-home-view {
  margin: 3rem 15rem 0 15rem;
}

.help-home-view .search-area {
  margin: 0 3rem 0 3rem;
}

.help-home-view .search-area > div {
  flex: auto;
  max-width: 25rem;
}

.help-home-view .search-area .IconButton svg {
  padding-bottom: 0.5rem;
  width: 2rem;
  height: 2rem;
}

.help-home-view .help-list .category-name {
  font-weight: bold;
}
